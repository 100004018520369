// colors
$dark: rgb(29, 29, 29);
$light: #fff;
$primary: rgb(162, 162, 246);
$bg: rgb(244, 244, 255);
$blue: #007bff;

// spacing
$spacing-md: 16px;
$spacing-lg: 32px;

// border radius
$borderRadius: 12px;

// header height
$headerHeight: 8rem;

@mixin breakpoint($point) {
    @if $point == md {
        // 768px
        @media (min-width: 48em) {
            @content;
        }
    }
}