@import '../../styles/variables.scss';

.header {
  &__content {
    transition: 0.3s ease all;
    box-shadow: 0rem 0rem 0.5rem rgba(0, 0, 0, 0.1);

    overflow: hidden;
    color: $dark;

    margin: 0 auto;
    // max-width: 1920px;

    height: $headerHeight;
    display: flex;
    align-items: center;

    width: 100%;

    position: relative;
    z-index: 100;

    @include breakpoint(md) {
    }

    &__nav {
      top: 0;
      right: 100%;
      bottom: 0;
      width: 100%;
      height: 100vh;
      position: fixed;
      display: flex;
      flex-direction: column;
      justify-content: center;

      text-align: center;
      background: rgba($light, 0.9);
      backdrop-filter: blur(2px);
      transform: translate(0);
      transition: 0.3s ease transform;

      @include breakpoint(md) {
        transform: none;
        background: transparent;
        width: 0%;
        position: static;
        margin: 2rem;
      }

      ul {
        list-style: none;
        padding: 0;
        display: flex;
        flex-direction: column;
        @include breakpoint(md) {
          flex-direction: row;
        }
        
        li:nth-child(5) {  
          margin-bottom: 0;
        }

        li {
          margin-bottom: $spacing-lg;
          font-size: 1.875rem;

   

          @include breakpoint(md) {
            margin-bottom: 0;
          }

          a {
            text-decoration: none;
            color: inherit;
            padding: 0.75rem 1.25rem;
            border-radius: $borderRadius;
            transition: 0.3s ease all;

            &:hover {
              background: rgba($light, 0.1);
            }
            &:active {
              border-radius: calc(#{$borderRadius} + 6px);
              background: linear-gradient(rgba($light, 0.1), rgba($light, 0.2));
            }
          }
        }
      }

      &.navlink {
        height: 0.3rem;
        background: #2b5876;
        width: 100%;
        bottom: 0;
        border-radius: 2px;
      }

      &.isMenu {
        transform: translate(100%);
      }
    }

    &__toggle {
      cursor: pointer;
      display: flex;
      align-items: center;
      font-size: 2rem;
      transition: 0.3s ease all;
      position: fixed;
      margin-left: 0.1rem;
      background: $light;
      padding: 0;
      margin: 0;
      border-radius: 0.1rem;

      &:hover {
        color: $primary;
      }

      @include breakpoint(md) {
        display: none;
      }
    }
  }
}
